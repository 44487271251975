<template>
  <div class="back-office">
    <Container>
      <Card padding="medium">
        <Margins>
          <h1 class="h3 text-normal">
            {{ $t('BACK_OFFICE') }}
            <small class="text-grey">| {{ $t('ORDERS') }}</small>
          </h1>
          <BackOfficeBundleOrders class="margins__triple" />
        </Margins>
      </Card>
    </Container>
  </div>
</template>

<script>
import { Container, Card, Margins } from '@/components';
import { BackOfficeBundleOrders } from '@/containers';

export default {
  name: 'Home',
  components: {
    Container,
    Card,
    Margins,
    BackOfficeBundleOrders,
  },
};
</script>
