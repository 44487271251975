<template>
  <div class="subscription">
    <Container class="text-center">
      <b-row align-h="center">
        <b-col md="9">
          <Margins>
            <h1>
              {{ $t('CALCULATE_MONTHLY_COST.TITLE') }}
            </h1>
            <p class="text-small pt-3 mt-3">
              {{ $t('CALCULATE_MONTHLY_COST.TEXT') }}
            </p>
          </Margins>
        </b-col>
      </b-row>

      <Separator size="small" />

      <b-row align-v="center">
        <b-col offset-md="1">
          <SliderField
            v-if="bundles"
            name="test"
            :steps="bundles"
            @change="checkedBundle = $event"
          />
        </b-col>
        <b-col cols="12" md="auto">
          <Button @click="selectBundle(checkedBundle)">
            {{ $t('APPLY_NOW') }}
          </Button>
        </b-col>
        <b-col cols="1" />
      </b-row>
    </Container>

    <Separator size="small" />

    <Container>
      <Separator size="medium" />

      <b-row align-h="center" class="gx-5">
        <b-col
          v-for="(bundle, index) in exampleBundles"
          :key="index"
          md="5"
          :class="{
            'pr-md-5': index % 2 == 0,
            'pl-md-5': Math.abs(index % 2) == 1,
          }"
        >
          <Card variant="corner-small" class="subrscription-card mb-5">
            <Card
              color="primary"
              text="white"
              class="subrscription-card__ribbon p-1"
            >
              <p
                class="subrscription-card__ribbon__text text-left text-uppercase pl-4 accent"
              >
                {{ $t('EXAMPLE') }} {{ index + 1 }}
              </p>
              <p
                class="subrscription-card__ribbon__text text-left pl-3 pr-4 ml-3"
              >
                {{ $t('SUBSCRIPTION') }}
                <span class="accent px-1">{{ bundle.monthlyPayment }}</span>
                {{ $t('KRONA_PER_MONTH') }}
              </p>
            </Card>
            <div class="subscription__clocks py-5">
              <img
                v-for="(clock, index) in clocks[bundle.monthlyPayment]"
                :key="`${bundle.id}-${index}`"
                :alt="clock.alt"
                :src="getImage(clock.src)"
                class="subscription__clock"
              />
            </div>
            <p class="text-small">
              {{ $t(`EXAMPLE_DESC.${bundle.monthlyPayment}`) }}
            </p>
          </Card>
        </b-col>
      </b-row>
    </Container>

    <SelectStore
      :view="true"
      @click="false"
      class="subscription__select-store"
    />
  </div>
</template>

<script>
import { constants } from '@/mixins';
import {
  Button,
  Container,
  Separator,
  Margins,
  Card,
  SliderField,
} from '@/components';
import { mapState, mapActions, mapMutations } from 'vuex';
import {
  GET_BUNDLES,
  RESET_STORE,
  SET_SIGNATURE_STATUS,
  SET_SELECTED_BUNDLE,
} from '@/types';
import SelectStore from '@/views/SelectStore/SelectStore';

export default {
  name: 'Subscription',
  mixins: [constants],
  components: {
    SelectStore,
    Container,
    Separator,
    Button,
    Margins,
    Card,
    SliderField,
  },
  data() {
    return {
      checkedBundle: null,
      exampleBundles: [],
      clocks: {
        99: [
          {
            src: 'clock_4.jpg',
            alt: 'Clock',
          },
          {
            src: 'clock_6.jpg',
            alt: 'Clock',
          },
        ],
        249: [
          {
            src: 'Exempel-1.png',
            alt: 'Clock',
          },
        ],
        499: [
          {
            src: 'Exempel-2.png',
            alt: 'Clock',
          },
        ],
      },
    };
  },
  async mounted() {
    this.RESET_STORE();
    this.SET_SIGNATURE_STATUS(null);

    await this.GET_BUNDLES();

    const monthlyPaymentExamples = [249, 499];

    this.exampleBundles = this.bundles
      .filter(({ monthlyPayment }) =>
        monthlyPaymentExamples.includes(monthlyPayment)
      )
      .sort((a, b) => a.monthlyPayment - b.monthlyPayment);
    this.checkedBundle = this.bundles[0];
  },
  methods: {
    ...mapActions([GET_BUNDLES, SET_SELECTED_BUNDLE]),
    ...mapMutations([RESET_STORE, SET_SIGNATURE_STATUS]),
    getImage(src) {
      return require(`@/assets/images/${src}`);
    },
    checkBundle(bundle) {
      this.checkedBundle = bundle;
    },
    selectBundle(bundle) {
      if (!bundle) {
        return false;
      }

      this.SET_SELECTED_BUNDLE(bundle);
      this.$router.push({ name: this.ROUTES.APPLY.name });
    },
  },
  computed: {
    ...mapState(['bundles', 'isLoading']),
  },
};
</script>
<style lang="scss">
.subscription__price-example {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-top: 3rem;

  @include min-width(md) {
    padding-top: 0;
  }
}

.subscription__clocks {
  display: flex;
  justify-content: center;
}

.subscription__clock {
  height: 7.7rem;
  width: auto;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
}

.subscription__price-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  background: $color-grey-light;
  padding: 1rem;

  @include min-width(md) {
    padding: 2rem;
  }

  .subscription__price-example--alternative & {
    background: $color-primary-hover;
    color: $color-white;
  }
}

.subscription__price-description {
  flex: 1 1 100%;
}

.subscription__quote {
  padding-top: 2rem;
}

.subscription__quote-logo {
  position: absolute;
  bottom: -30%;
  right: 4%;
  z-index: 1;

  @include min-width(md) {
    bottom: -90%;
    right: 10%;
  }
}

.subscription__bundles {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;

  @include min-width(sm) {
    grid-template-columns: 1fr 1fr;
  }

  @include min-width(lg) {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@include min-width(md) {
  .subscription__select-store {
    margin-bottom: -5rem;
  }
}

// Cards

.subrscription-card {
  padding: 1.5rem;
}

.subrscription-card__ribbon {
  width: calc(100% + 4rem);
  margin-left: -2rem;
  border-radius: 3px;

  @include min-width(md) {
    width: max-content;
    margin-left: -2.5rem;
  }

  .subrscription-card__ribbon__text {
    display: inline-flex;
    vertical-align: text-top;
  }
  .subrscription-card__ribbon__text + .subrscription-card__ribbon__text {
    border-left: 0.5px solid rgba(255, 255, 255, 0.5);
  }

  & .accent {
    font-family: $font-family-header;
  }
}
</style>
